@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Gill Sans";
    font-display: swap;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    src: url("assets/fonts/gill-sans.ttf")format("truetype")
      url("assets/fonts/gill-sans.woff")format("woff"),
      url("assets/fonts/gill-sans.woff2")format("woff2");
  }

  @font-face {
    font-family: "ET Book";
    src: url("assets/fonts/et-book.eot");
    src: url("assets/fonts/et-book.eot?#iefix") format("embedded-opentype"),
      url("assets/fonts/et-book.woff") format("woff"),
      url("assets/fonts/et-book.ttf") format("truetype"),
      url("assets/fonts/et-book.svg#etbookromanosf") format("svg");
    font-weight: 400;
    font-style: normal
  }
}
